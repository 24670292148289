import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import '../hojas-css/slider2.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function App() {
    const [data, setData] = useState([]);
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/promociones.php?promociones`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setData(data);
            })
            .catch(error => console.error('Error al obtener los datos:', error));
    }, []);

    const onAutoplayTimeLeft = (s, time, progress) => {
        if (progressCircle.current) {
            progressCircle.current.style.setProperty('--progress', 1 - progress);
        }
        if (progressContent.current) {
            progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
        }
    };

    return (
        <div className='promoSlider'>
            <Swiper
                slidesPerView={2}
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                    el: '.swiper-pagination', // Usamos la clase predeterminada de Swiper
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className="promoswiper"
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                }}
            >
                {data.map((promo, index) => (
                    <SwiperSlide key={index}>
                        <div className="slide-container">
                            <img src={promo.promoImagen} alt="Imagen de promo" className="taxi-image" />
                            <div className='info-btn'>
                                <div className="taxi-info">
                                    <div className='titulo-1'>
                                        <h2>{promo.nombEmp}</h2>
                                    </div>
                                    <div className='contenido'>
                                        {promo.descripcion && (
                                            <p>{promo.descripcion}</p>
                                        )}
                                        <p><i className="fa-solid fa-location-dot"></i> Direccion: {promo.direccion}</p>
                                        {promo.sitioWeb && (
                                            <div className="link-item">
                                                <a href={promo.sitioWeb} style={{ color: 'blue' }}><i className="fa-solid fa-globe"></i> sitio Web</a>
                                            </div>
                                        )}
                                        {promo.facebook && (
                                            <div className="link-item">
                                                <a href={promo.facebook} style={{ color: 'blue' }}><i className="fab fa-facebook"></i> Facebook</a>
                                            </div>
                                        )}
                                    </div>
                                    <div className='iconos-horizontal'>
                                        {promo.whatsapp && (
                                            <div className="link-item">
                                                <a href={promo.whatsapp} className="icon-link" style={{ color: 'green' }}>
                                                    <i className="fa-brands fa-whatsapp"></i>
                                                    <span className="icon-text">whatsapp</span>
                                                </a>
                                            </div>
                                        )}
                                        {promo.numtelefono && (
                                            <div className="link-item">
                                                <a href={promo.numtelefono} className="icon-link" style={{ color: '#0088cc' }}>
                                                    <i className="fa-solid fa-phone"></i>
                                                    <span className="icon-text">telefono</span>
                                                </a>
                                            </div>
                                        )}
                                        {promo.comoLlegar && (
                                            <div className="link-item">
                                                <a href={promo.comoLlegar} className="icon-link" style={{ color: '#666' }}>
                                                    <i className="fa-solid fa-car"></i>
                                                    <span className="icon-text">Como Llegar</span>
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
                <div className="autoplay-progress" slot="container-end">
                    <svg viewBox="0 0 48 48" ref={progressCircle}>
                        <circle cx="24" cy="24" r="20"></circle>
                    </svg>
                    <span ref={progressContent}></span>
                </div>
                <div className="swiper-pagination"></div>
            </Swiper>
        </div>
    );
}
