import '../hojas-css/globalSearch.css';
const GlobalSearch = ({ searchTerm, onSearchChange }) => {
    return (
        <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => onSearchChange(e.target.value)}
            className="search-input"
        />
    );
};

export default GlobalSearch;
