import React, { useState, useEffect } from 'react';
import Navbar from '../../componentes/navbar.js';
import Slider from '../../componentes/slider.js';
import { MapaSeguros} from '../../componentes/map.js';
import { BotonClinicas} from '../../componentes/botones2.js';
import Footer from '../../componentes/footer.js';
import CustomizedDialogs from './modalClinica.js';
import GlobalSearch from '../../componentes/GlobalSearch.js';
import '../../hojas-css/miniban.css';


function Clinicas() {
    const [data, setData] = useState([]);
    const [selectedclin, setSelectedclin] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/clinicas.php?clinicas`)
        .then(response => response.json())
        .then(data => {
            setData(data);
            setData(data.filter(item => item.id_roles === 17));
        })
        .catch(error => console.error('Error al obtener los datos:', error));
    }, []);

    const handleClickVerMas = (clin) => {
        setSelectedclin(clin);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


    const filteredData = data.filter(clin =>
        clin.nombClin.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="App">
            
            <div className='navbar-container'>
                <Navbar />
            </div>

            <div className='slider-container'>
                <Slider />
            </div>
            <h1 className="titulo-2">Servicios Medicos</h1>
            <GlobalSearch searchTerm={searchTerm} onSearchChange={setSearchTerm} />
            <div className='contenedor-padre2'> 
                <div className='contenedor-hijo'>
                        <div className='botones-container'>
                            <BotonClinicas />
                        </div>

                        <div className='miniban-container'>
                            <div className="column-container">
                                <h1>Clinicas</h1>
                                {filteredData.map((clin, index) => (
                                    <div key={index} className="miniban-container">
                                        <img src={clin.imgClin} alt="Imagen de promo" className="miniban-image" />
                                        <div className="miniban-info">
                                            <h2>{clin.nombClin}</h2>
                                            {clin.descripcion && (
                                                <p style={{ color: '#F5F5F5' }}>
                                                    {clin.descripcion}
                                                    </p>
                                            )}
                                            <p style={{ color: '#336699' }}>
                                                <i className="fa-solid fa-location-dot"> </i> Direccion: {clin.direccion}
                                            </p>
                                            
                                            {clin.sitoWeb ? (
                                                <div className="link-item">
                                                <a href={clin.sitoWeb} style={{ color: '#6495ED' }}><i className="fa-solid fa-globe"></i> sitio Web</a>
                                                </div>
                                            ): null}

                                            <div className='iconos-horizontal'>
                                                {clin.facebook ? (
                                                    <div className="link-item">
                                                        <a href={clin.facebook} style={{ color: 'blue' }}>
                                                        <i className="fab fa-facebook"></i> Facebook</a>
                                                    </div>
                                                ): null}
                                                
                                                {clin.whatsapp ? (
                                                    <div className="link-item">
                                                        <a href={clin.whatsapp} style={{ color: 'green' }}>
                                                        <i className="fa-brands fa-whatsapp"></i> whatsapp</a>
                                                    </div>
                                                ): null}
                                                {clin.telefono ? (
                                                    <div className="link-item">
                                                        <a href={clin.telefono} style={{ color: '#ADD8E6' }}>
                                                        <i className="fa-solid fa-phone"></i> telefono</a>
                                                    </div>
                                                ) : null}
                                                {clin.comoLlegar ? (
                                                    <div className="link-item">
                                                        <a href={clin.comoLlegar} style={{ color: '#c0bebe' }}>
                                                        <i className="fa-solid fa-car"></i> Como Llegar</a>
                                                    </div>
                                                ): null}
                                            </div>
                                            <button onClick={() => handleClickVerMas(clin)} className="ver-mas-btn">Ver Especialidades</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            
                            <CustomizedDialogs 
                                open={isModalOpen}
                                handleClose={handleCloseModal}
                                clin={selectedclin}
                            />

                        </div>
                </div>
                <div className='mapa-container'>
                    <MapaSeguros searchTerm={searchTerm}/>
                </div>
            </div>

            <div className='footer-container'>
                <Footer />
            </div>
            
        </div>
    );
}

export default Clinicas;
