import React, { useState } from 'react';
import axios from 'axios';

function EditarElemento({ tabla, elemento, onCancel }) {
    const [editedElemento, setEditedElemento] = useState({ ...elemento });
    const [imagenes, setImagen] = useState(null);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditedElemento({ ...editedElemento, [name]: value });
    };

    const handleImagenChange = (event) => {
        const selectedFile = event.target.files[0];
        setImagen(selectedFile);
        //const name = event.target.name;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Tabla:", tabla);
        const formData = new FormData();
        
        // Agregar el campo de imagen si corresponde
        const tablaConfigs = [
            { tabla: 'taxi_miniban', field: 'imagen' },
            { tabla: 'promociones', field: 'promoImagen' },
            { tabla: 'empresa_movilida', field: 'imagenMovilidad' },
            { tabla: 'general_movilidad', field: 'imgGeneral' },
            { tabla: 'restaurantes', field: 'imagenRestau' },
            { tabla: 'general_restaurante', field: 'imgPlato' },
            { tabla: 'hoteles', field: 'imgHotel' },
            { tabla: 'general_hotel', field: 'imgCuatos' },
            { tabla: 'carwash', field: 'imgCarwash' },
            { tabla: 'general_carwash', field: 'imgLavado' },
            { tabla: 'campodeportivo', field: 'imgComplejo' },
            { tabla: 'general_campodeportivo', field: 'imgCancha' },
            { tabla: 'clinicas', field: 'imgClin' },
            { tabla: 'cochera', field: 'imgCoch' },
            { tabla: 'general_clinicas2', field: 'imgEsp' },
            { tabla: 'general_cochera', field: 'imgCochera' },
            { tabla: 'mecanicos', field: 'imgMecanico' },
            { tabla: 'general_mecanico', field: 'imgGenMecanico' },
        ];
        
        for (const config of tablaConfigs) {
            if (config.tabla === tabla && editedElemento[config.field]) {
                formData.append(config.field, imagenes);
            }
        }
        // Agregar otros campos
        for (const key in editedElemento) {
            formData.append(key, editedElemento[key]);
            console.log(`${key} `, editedElemento[key]);
        }

        axios.put(`${process.env.REACT_APP_API_URL}/editar.php?tabla=${tabla}&id=${elemento.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            console.log("Elemento editado:", response.data);
            // Recargar la página después de editar exitosamente
            window.location.reload();
        })
        .catch(error => {
            console.error('Error al editar elemento:', error);
        });
    };
    

    return (
        <form onSubmit={handleSubmit} className="edit-container">
            {/* Renderizar los campos según la estructura de la tabla */}
            {Object.keys(elemento).map((campo, index) => (
                // Verificar si el campo es de tipo imagen y renderizar un input de tipo file
                (campo === 'imagen' && tabla === 'taxi_miniban') || 
                (campo === 'promoImagen' && tabla === 'promociones')|| 
                (campo === 'imagenMovilidad' && tabla === 'empresa_movilida')||
                (campo === 'imgGeneral' && tabla === 'general_movilidad')||
                (campo === 'imagenRestau' && tabla === 'restaurantes')||
                (campo === 'imgPlato' && tabla === 'general_restaurante')||
                (campo === 'imgHotel' && tabla === 'hoteles')||
                (campo === 'imgCuatos' && tabla === 'general_hotel')||
                (campo === 'imgCarwash' && tabla === 'carwash')||
                (campo === 'imgLavado' && tabla === 'general_carwash')||
                (campo === 'imgComplejo' && tabla === 'campodeportivo')||
                (campo === 'imgCancha' && tabla === 'general_campodeportivo')||
                (campo === 'imgClin' && tabla === 'clinicas')||
                (campo === 'imgCoch' && tabla === 'cochera')||
                (campo === 'imgEsp' && tabla === 'general_clinicas2')||
                (campo === 'imgCochera' && tabla === 'general_cochera')||
                (campo === 'imgMecanico' && tabla === 'mecanicos')||
                (campo === 'imgGenMecanico' && tabla === 'general_mecanico')? (
                    <input 
                        key={index} 
                        type="file" 
                        name={campo === 'imagen' ? campo : 'promoImagen' ? campo : 'imagenMovilidad'  
                            ? campo : 'imgGeneral' ? campo : 'imagenRestau' ? campo : 'imgPlato' ? campo : 'imghotel'
                            ? campo : 'imgCuatos' ? campo : 'imgCarwash' ? campo : 'imgLavado' ? campo : 'imgComplejo'
                            ? campo : 'imgCancha' ?   campo : 'imgClin'  ?  campo : 'imgCoch' ?  campo : 'imgEsp'
                            ?  campo : 'imgCochera' ?  campo : 'imgMecanico' ?  campo : 'imgGenMecanico'
                            }
                        onChange={handleImagenChange} 
                    />
                ) : (
                    // Renderizar un input de texto para otros campos
                    <input 
                        key={index} 
                        type="text" 
                        name={campo} 
                        value={editedElemento[campo]} 
                        onChange={handleChange} 
                    />
                )
            ))}
            <button type="submit">Guardar Cambios</button>
            <button type="button" onClick={onCancel}>Cancelar</button>
        </form>
    );
}

export default EditarElemento;