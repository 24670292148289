import React from 'react';
import '../hojas-css/boton.css'

function Footer() {
    return (
        <section>
            <footer className="footer">
                <div className="link">
                    <h4>contactanos</h4>
                    <ul>
                        <li><a href="https://bit.ly/3NRs6w5"><i className="fa-brands fa-whatsapp"></i> whatsapp</a></li>
                        <li><a href="mailto:jordyasd12@gmail.com"><i className="fa-solid fa-envelope"></i> gmail</a></li>
                        <li><a href="https://m.me/jor10dy"><i className="fa-brands fa-facebook-messenger"></i> Messenger</a></li>
                        <li><a href="tel:+51900759806"><i className="fa-solid fa-phone"></i> telefono</a></li>
                    </ul>
                </div>

                <div className="link">
                    <h4>Redes Sociales</h4>
                    <ul>
                        <li><a href="https://web.facebook.com/jor10dy/"><i className="fa-brands fa-facebook"></i> facebook</a></li>
                        <li><a href="https://web.facebook.com/jor10dy/"><i className="fa-brands fa-twitter"></i> twitter</a></li>
                        <li><a href="https://web.facebook.com/jor10dy/"><i className="fa-brands fa-instagram"></i>instagram</a></li>
                    </ul>
                </div>
            </footer>
            <div className="grupo-2">
                <small>&copy; 2024 <b>Jordy A. R.</b> - Todos los derechos reservados</small>
            </div>
        </section>
    );
}

export default Footer;
