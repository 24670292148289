import React, { useState } from 'react';
import axios from 'axios';

function RegistroUsuario({ campos, onRegister, onCancel, tablaSeleccionada }) {
    const [formData, setFormData] = useState({});
    const [imagen, setImagen] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(`Nombre del campo: ${name}, Valor del campo: ${value}`);
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };
    
    

    const handleImageChange = (e) => {
        setImagen(e.target.files[0]);
        console.log("Imagen seleccionada:", e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const formDataToSend = new FormData();
    
            // Agregar campos de texto al formData
            for (const key in formData) {
                console.log(`Campo: ${key}, Valor: ${formData[key]}`); // Agregar log para imprimir los datos del formulario
                formDataToSend.append(key, formData[key]);
            }
    
            // Agregar imagen al formData si hay una imagen seleccionada
            if (imagen) {
                if (tablaSeleccionada.nombre === 'taxi_miniban') {
                    formDataToSend.append('imagen', imagen);
                } else if (tablaSeleccionada.nombre === 'promociones') {
                    formDataToSend.append('promoImagen', imagen);
                }else if(tablaSeleccionada.nombre === 'empresa_movilida'){
                    formDataToSend.append('imagenMovilidad', imagen);
                }
            }
    
            // Verificar el contenido de formDataToSend
            for (var pair of formDataToSend.entries()) {
                console.log(pair[0] + ', ' + pair[1]); 
            }
            
            console.log("URL de la solicitud:", tablaSeleccionada.url);
    
            // Realizar la solicitud POST
            const response = await axios.post(tablaSeleccionada.url, formDataToSend);
            console.log("Elemento registrado:", response.data);
            onRegister();
        } catch (error) {
            console.error(`Error al registrar elemento en ${tablaSeleccionada.nombre}:`, error.response ? error.response.data : error.message);
        }
    };
    
    

    return (
        <form onSubmit={handleSubmit} className="registrar-container" encType="multipart/form-data">
            {/* Renderizar campos de acuerdo a la tabla seleccionada */}
            {Object.keys(campos).map((campo, index) => (
                <div key={index}>
                    <label htmlFor={campo}>{campo}</label>
                    {/* Verificar si el campo es 'imagen' o 'pomoImagen' */}
                    {(campo === 'imagen' && tablaSeleccionada.nombre === 'taxi_miniban') ||
                        (campo === 'promoImagen' && tablaSeleccionada.nombre === 'promociones')||
                        (campo === 'imagenMovilidad' && tablaSeleccionada.nombre === 'empresa_movilida') ? (
                            <input
                                type="file"
                                id={campo}
                                name={campo}
                                onChange={handleImageChange}
                            />
                        ) : (
                            <input
                                type="text"
                                id={campo}
                                name={campo}
                                value={formData[campo] || ''}
                                onChange={handleInputChange}
                            />
                        )}
                </div>
            ))}
            <button type="submit">Registrar</button>
            <button type="button" onClick={onCancel}>Cancelar</button>
        </form>
    );
}

export default RegistroUsuario;
