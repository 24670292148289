import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import '../../hojas-css/modal.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CustomizedDialogs({ open, handleClose, hotel }) {
    const [empleados, setEmpleados] = React.useState([]);
    const [nombHoteles, setNombEmpresa] = React.useState('');

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/hoteles.php?nombHoteles=${hotel.nombHotel}`);
                //console.log("Respuesta del servidor:", response.data);
                setEmpleados(response.data.data);
                setNombEmpresa(response.data.data.length > 0 ? response.data.data[0].nombHoteles : '');
            } catch (error) {
                console.error("Error al obtener los datos:", error);
            }
        };

        if (open && hotel) {
            fetchData();
        }
    }, [open, hotel]);

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <div className="dialog-header">
                <DialogTitle sx={{ m: 0, p: 2 }} variant="h4" className="empleado-dialog-title">
                    {nombHoteles ? `${nombHoteles} ` : 'Error al buscar'}
                </DialogTitle>
                <div className="close-button-container">
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <DialogContent dividers className="dialog-content-custom">
                {Array.isArray(empleados) && empleados.length > 0 ? (
                    <>
                        {empleados.filter(emp => emp.fachada === 'si').map((emp, index) => (
                            <div className="empleado-container" key={index}>
                                <div className='fachada-container'>
                                    <img src={`${process.env.REACT_APP_API_URL}/imagenes/${emp.imgCuatos}`} alt="Imagen Fachada" className='imgFachada-image' style={{ width: '100%' }} />
                                </div>
                            </div>
                        ))}
                        {empleados.filter(emp => emp.fachada !== 'si').map((emp, index) => (
                            <div className="empleado-container" key={index}>
                                <div className='padre-conteiner'>
                                    <div className='imagen-container'>
                                        <img src={`${process.env.REACT_APP_API_URL}/imagenes/${emp.imgCuatos}`} alt="Imagen General" className='imgGeneral-image'/>
                                    </div>
                                    <div className='madre-container'>
                                        <Typography variant="h5" gutterBottom>
                                            <div className="empleado-title">
                                                {emp.nombre}
                                            </div>
                                            <div className='empleado-info3' >
                                                {emp.tv !== 'no' && (
                                                    <p>
                                                        <i className="fa-solid fa-tv"></i> TV: {emp.tv}
                                                    </p>
                                                )}
                                                {emp.wifi === 'si' && (
                                                    <p>
                                                        <i className="fa-solid fa-wifi"></i> WIFI
                                                    </p>
                                                )}
                                                {emp.baño && (
                                                    <p>
                                                        <i className="fa-solid fa-bath"></i> Baño {emp.baño}
                                                    </p>
                                                )}
                                                {emp.jacuzzi === 'si' && (
                                                    <p>
                                                        <i className="fa-solid fa-hot-tub"></i> Jacuzzi
                                                    </p>
                                                )}
                                            </div>
                                        </Typography>

                                        <div className="empleado-info">
                                            <p>Precio/hora:</p><p>$/{emp.precioHora}</p>
                                            <p>Precio/dia:</p><p>$/{emp.precioDia}</p>
                                        </div>
                                        
                                        <div className='datos-normal'>
                                            {emp.whatsapp && (
                                                <div className="datos-item">
                                                    <a href={emp.whatsapp} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> WhatsApp</a>
                                                </div>
                                            )}
                                            {emp.telefono && (
                                                <div className="datos-item">
                                                    <a href={emp.telefono} style={{ color: '#333' }}><i className="fa-solid fa-phone"></i> Resevaciones</a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                    <div className='datos-resposive'>
                                        {emp.whatsapp && (
                                            <div className="datos-item">
                                                <a href={emp.whatsapp} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> WhatsApp</a>
                                            </div>
                                        )}
                                        {emp.telefono && (
                                            <div className="datos-item">
                                                <a href={emp.telefono} style={{ color: '#333' }}><i className="fa-solid fa-phone"></i> Resevaciones</a>
                                            </div>
                                        )}
                                    </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <Typography variant="body1">
                        No se encontraron datos del hotel.
                    </Typography>
                )}
            </DialogContent>
        </BootstrapDialog>
    );
}
