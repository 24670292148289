import React, { useState, useEffect } from 'react';
import Navbar from '../../componentes/navbar.js';
import Slider from '../../componentes/slider.js';
import { MapaCampoDep } from '../../componentes/map.js';
import Footer from '../../componentes/footer.js';
import CustomizedDialogs from './modalCampoD';
import GlobalSearch from '../../componentes/GlobalSearch.js';
import '../../hojas-css/miniban.css';

function Campo() {
    const [data, setData] = useState([]);
    const [selectedMovil, setSelectedMovil] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/campodeportivo.php?campodeportivo`)
            .then(response => response.json())
            .then(data => {
                setData(data); 
            })
            .catch(error => console.error('Error al obtener los datos:', error));
    }, []);

    const handleClickVerMas = (deporte) => {
        setSelectedMovil(deporte);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const filteredData = data.filter(deporte =>
        deporte.nombComplejo.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="App">
            <div className='navbar-container'>
                <Navbar />
            </div>

            <div className='slider-container'>
                <Slider />
            </div>
            <GlobalSearch searchTerm={searchTerm} onSearchChange={setSearchTerm} />
            <div className='contenedor-padre'> 
                <div className='contenedor-hijo2'>
                
                    
                    <div className='miniban-container'>
                        <div className="column-container">
                            <h1> Complejos Deportivos </h1>
                            {filteredData.map((deporte, index) => (
                                <div key={index} className="miniban-container">
                                    <img src={deporte.imgComplejo} alt="Imagen de promo" className="miniban-image" />
                                    <div className="miniban-info">
                                        <h2>{deporte.nombComplejo}</h2>
                                        <div className='empleado-info4' >
                                            <p style={{ whiteSpace: 'pre-wrap' }}>
                                                {`- ${deporte.servicios.replace(/,/g, '\n- ')}`}
                                            </p>
                                        </div>
                                        <p><i className="fa-solid fa-location-dot"></i> Dirección: {deporte.direccion}</p>
                                        {deporte.cochera === 'si' && (
                                            <p>
                                                <i className="fa-solid fa-warehouse"></i> Cochera 
                                            </p>
                                        )}
                                        {deporte.facebook && (
                                            <div className="link-item">
                                                <a href={deporte.facebook} style={{ color: 'blue' }}><i className="fab fa-facebook"></i> Facebook</a>
                                            </div>
                                        )}
                                        <div className='iconos-horizontal'>
                                            {deporte.whatsapp && (
                                                <div className="link-item">
                                                    <a href={deporte.whatsapp} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> WhatsApp</a>
                                                </div>
                                            )}
                                            {deporte.telefono && (
                                                <div className="link-item">
                                                    <a href={deporte.telefono} style={{ color: '#0088cc' }}><i className="fa-solid fa-phone"></i> Reservaciones</a>
                                                </div>
                                            )}
                                            {deporte.comoLlegar && (
                                                <div className="link-item">
                                                    <a href={deporte.comoLlegar} style={{ color: '#666' }}><i className="fa-solid fa-car"></i> Cómo Llegar</a>
                                                </div>
                                            )}
                                        </div>
                                        <button onClick={() => handleClickVerMas(deporte)} className="ver-mas-btn">Ver canchas</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <CustomizedDialogs 
                            open={isModalOpen}
                            handleClose={handleCloseModal}
                            deporte={selectedMovil}
                        />
                    </div>
                </div>
                <div className='mapa-container'>
                    <MapaCampoDep searchTerm={searchTerm} />
                </div>
            </div>

            <div className='footer-container'>
                <Footer />
            </div>
        </div>
    );
}

export default Campo;
