import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import '../hojas-css/boton.css'


function BotonPrincipal() {
  return (
    <section className="opciones">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />
      <h1 className="titulo-2">HUARAL</h1>
      <div className="tarjetas container">
        <Link to="/minivan" className="tarjeta">
          <i className="fa-solid fa-car"></i>
          <h3>Mobilidad</h3>
          <p>Opciones de transporte disponibles.</p>
        </Link>
        
        <Link to="/polleria" className="tarjeta">
          <i className="fa-solid fa-utensils"></i>
          <h3>Restaurantes</h3>
          <p>Descubre los mejores lugares para comer.</p>
        </Link>

        <Link to="/hotel" className="tarjeta">
          <i className="fa-solid fa-hotel"></i>
          <h3>Hotel</h3>
          <p>Encuentra alojamiento cómodo.</p>
        </Link>

        <Link to="/campo" className="tarjeta">
          <i className="fa-regular fa-futbol"></i>
          <h3>Capos deportivos</h3>
          <p>Áreas para actividades deportivas.</p>
        </Link>

        <Link to="/carwash" className="tarjeta">
          <i className="fa-solid fa-droplet"></i>
          <h3>Car Wash</h3>
          <p>Servicios de lavado de autos.</p>
        </Link>
        <Link to="/cocheras" className="tarjeta">
          <i className="fa-solid fa-warehouse"></i>
          <h3>Cocheras</h3>
          <p>Espacios para estacionar vehículos.</p>
        </Link>
        
        <Link to="/clinicas" className="tarjeta">
          <i className="fa-solid fa-hospital-user"></i>
          <h3>Clínicas</h3>
          <p>Servicios médicos y clínicas.</p>
        </Link>

        <Link to="/mecanicos" className="tarjeta">
          <i className="fa-solid fa-wrench"></i>
          <h3>Mecánicos</h3>
          <p>Servicios de reparación y mantenimiento de vehículos.</p>
        </Link>
      </div>
    </section>
  );
}

function BotonMovilidad({ handleClick }) {
  return (
    <section className="botn">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />
      <div>
        <Swiper
          slidesPerView={2}
          spaceBetween={3} // Ajusta el espacio entre los slides
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          breakpoints={{
            640: {
              slidesPerView: 3, // Muestra 3 botones por slide cuando el ancho de la pantalla es 640px o más
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 3, // Muestra 4 botones por slide cuando el ancho de la pantalla es 768px o más
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 4, // Muestra 5 botones por slide cuando el ancho de la pantalla es 1024px o más
              spaceBetween: 0,
            },
          }}
          className="mySwiper"
        >
          <div className='boton'>
            <SwiperSlide>
            <Link to="/minivan" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-car"></i> Minivan</Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/taxi" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-car-side"></i> Taxi</Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/mototaxi" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-motorcycle"></i> Mototaxi</Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/combi" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-van-shuttle"></i> Combi</Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/bus" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-bus-simple"></i> Buss</Link>
          </SwiperSlide>
          </div>
          
        </Swiper>
      </div>
    </section>
  );
}

function BotonRestaurante( handleClick) {
  return (
    <section className="botn">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />
      <div>
        <Swiper
          slidesPerView={2}
          spaceBetween={3} // Ajusta el espacio entre los slides
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          breakpoints={{
            640: {
              slidesPerView: 3, // Muestra 3 botones por slide cuando el ancho de la pantalla es 640px o más
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 4, // Muestra 4 botones por slide cuando el ancho de la pantalla es 768px o más
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 4, // Muestra 5 botones por slide cuando el ancho de la pantalla es 1024px o más
              spaceBetween: 0,
            },
          }}
          className="mySwiper"
        >
          <div className='boton'>
            <SwiperSlide>
            <Link to="/polleria" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-drumstick-bite" ></i> Polleria</Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/chifa" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-fire-flame-curved"></i>chifas</Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/cevicheria" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-fire-flame-curved"></i>Cevicheria</Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/campestre" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-utensils"></i>  campestres</Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/restobar" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-martini-glass-citrus"></i> restobar</Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/Mixtos" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-fire-flame-curved"></i> mixtos</Link>
          </SwiperSlide>
          </div>
          
        </Swiper>
      </div>
    </section>
  );

}

function BotonClinicas({ handleClick }) {
  return (
    <section className="botn">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css" />
      <div>
      
        <Swiper
          slidesPerView={2}
          spaceBetween={3} // Ajusta el espacio entre los slides
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          breakpoints={{
            640: {
              slidesPerView: 3, // Muestra 3 botones por slide cuando el ancho de la pantalla es 640px o más
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 3, // Muestra 4 botones por slide cuando el ancho de la pantalla es 768px o más
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 3, // Muestra 5 botones por slide cuando el ancho de la pantalla es 1024px o más
              spaceBetween: 0,
            },
          }}
          className="mySwiper"
        >
          <div className='boton'>
            <SwiperSlide>
            <Link to="/clinicas" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-house-chimney-medical"></i> Clinicas</Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/seguros" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-notes-medical"></i> Seguros</Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/postas" className="btn btn-1" style={{ width: '75%' }}><i className="fa-solid fa-truck-medical"></i> Postas</Link>
          </SwiperSlide>
          </div>
          
        </Swiper>
      </div>
    </section>
  );
}

export { BotonPrincipal, BotonMovilidad, BotonRestaurante, BotonClinicas };