import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../hojas-css/crud.css';
import EditarUsuario from './editar';
import RegistroUsuario from './registar';


function ListaUsuarios() {
    const [tablaSeleccionada, setTablaSeleccionada] = useState(null);
    const [datosTabla, setDatosTabla] = useState([]);
    const [editingUserId, setEditingUserId] = useState(null);
    const [showRegistro, setShowRegistro] = useState(false);

    const tablas = [
        { 
            nombre: 'usuarios', 
            url: `${process.env.REACT_APP_API_URL}/usuarios2.php?usuario`,
            campos: {
                nombre: '',
                correo: '', 
                usuario: '', 
                contraseña: '',
            }
        },

        { 
            nombre: 'promociones', 
            url: `${process.env.REACT_APP_API_URL}/promociones.php?promociones`,
            campos: {
                nombEmp:'',
                descripcion:'',
                direccion:'',
                sitioWeb:'',
                numtelefono:'',
                whatsapp:'',
                facebook:'',
                comoLlegar:'',
                promoImagen:'',
            }
        },

        { 
            nombre: 'empresa_movilida', 
            url: `${process.env.REACT_APP_API_URL}/movilidad.php?movilidad`,
            campos: {
                nombEmp:'',
                descripcion:'',
                direccion:'',
                ruta:'',
                facebook:'',
                wasap:'',
                telefono:'',
                comoLlegar:'',
                id_roles:'',
                imagenMovilidad:'',
                
            }
        },

        { 
            nombre: 'general_movilidad', 
            url: `${process.env.REACT_APP_API_URL}/movilidad.php?general_movilidad`,
            campos: {
                fachada:'',
                nombre:'',
                apellidos:'',
                dni:'',
                nroLicencia:'',
                clase: '',
                matricula: '',
                numeroTelefono:'',
                whatsapp:'',
                facebook:'',
                imgGeneral:'',
                nombEmpresa:'',
                
            }
        },

        { 
            nombre: 'restaurantes', 
            url: `${process.env.REACT_APP_API_URL}/restaurantes.php?restaurantes`,
            campos: {
                nombre:'',
                descripcion:'',
                sitioWeb:'',
                direccion:'',
                facebook: '',
                wasap: '',
                telefono:'',
                comoLlegar:'',
                id_roles:'',
                imagenRestau:'',
                
            }
        },

        { 
            nombre: 'general_restaurante', 
            url: `${process.env.REACT_APP_API_URL}//restaurantes.php?general_restaurante`,
            campos: {
                fachada:'',
                nombrePlato:'',
                descripcion:'',
                precio:'',
                wasap:'',
                telefono: '',
                nombPolleria: '',
                imgPlato:'',
            }
        },

        { 
            nombre: 'hoteles', 
            url: `${process.env.REACT_APP_API_URL}/hoteles.php?hoteles`,
            campos: {
                nombHotel:'',
                descripcion:'',
                direccion:'',
                sitoWeb:'',
                facebook:'',
                cochera:'',
                whatsapp:'',
                telefono:'',
                comoLlegar:'',
                imgHotel:'',
            }
        },

        { 
            nombre: 'general_hotel', 
            url: `${process.env.REACT_APP_API_URL}/hoteles.php?general_hotel`,
            campos: {
                fachada:'',
                nombre:'',
                tv:'',
                wifi:'',
                baño:'',
                jacuzzi:'',
                precioHora:'',
                precioDia:'',
                whatsapp:'',
                telefono:'',
                imgCuatos:'',
            }
        },

        { 
            nombre: 'carwash', 
            url: `${process.env.REACT_APP_API_URL}/carwash.php?carwash`,
            campos: {
                nombCarwash:'',
                descripcion:'',
                direccion:'',
                sitoWeb:'',
                facebook:'',
                whatsapp:'',
                telefono:'',
                comoLlegar:'',
                imgCarwash:'',
            }
        },
        { 
            nombre: 'general_carwash', 
            url: `${process.env.REACT_APP_API_URL}/carwash.php?general_carwash`,
            campos: {
                fachada:'',
                nombServicio:'',
                descripcion:'',
                precMoto_Estandar:'',
                precMoto_Grande:'',
                precAuto:'',
                precCamioneta:'',
                precMinivan:'',
                PrecVan:'',
                precExtras:'',
                tipos:'',
                whatsapp:'',
                telefono:'',
                nombCarw:'',
                imgLavado:'',
            }
        },

        { 
            nombre: 'campodeportivo', 
            url: `${process.env.REACT_APP_API_URL}/campodeportivo.php?campodeportivo`,
            campos: {
                nombComplejo:'',
                servicios:'',
                direccion:'',
                facebook:'',
                whatsapp:'',
                telefono:'',
                comoLlegar:'',
                imgComplejo:'',
            }
        },
        { 
            nombre: 'general_campodeportivo', 
            url: `${process.env.REACT_APP_API_URL}/campodeportivo.php?general_campodeportivo`,
            campos: {
                fachada:'',
                nombCacha:'',
                jugadores:'',
                tipoPiso:'',
                infraestructura:'',
                precioDia:'',
                precioNoche:'',
                precioEventos:'',
                whatsapp:'',
                telefono:'',
                nombCampo:'',
                imgCancha:'',
            }
        },

        { 
            nombre: 'clinicas', 
            url: `${process.env.REACT_APP_API_URL}/clinicas.php?clinicas`,
            campos: {
                nombClin:'',
                descripcion:'',
                direccion:'',
                sitoWeb:'',
                facebook:'',
                whatsapp:'',
                telefono:'',
                comoLlegar:'',
                id_roles:'',
                imgClin:'',
            }
        },

        { 
            nombre: 'general_clinicas2', 
            url: `${process.env.REACT_APP_API_URL}/clinicas.php?general_clinicas2`,
            campos: {
                fachada:'',
                especialidades:'',
                nombClinica:'',
                imgEsp:'',
            }
        },

        { 
            nombre: 'cochera', 
            url: `${process.env.REACT_APP_API_URL}/cocheras.php?cochera`,
            campos: {
                nombCoch:'',
                descripcion:'',
                direccion:'',
                facebook:'',
                whatsapp:'',
                telefono:'',
                comoLlegar:'',
                imgCoch:'',
            }
        },

        { 
            nombre: 'general_cochera', 
            url: `${process.env.REACT_APP_API_URL}/cocheras.php?general_cochera`,
            campos: {
                fachada:'',
                nombreGeneCoch:'',
                descripcion:'',
                nombCochera:'',
                whatsapp:'',
                telefono:'',
                comoLlegar:'',
                imgCochera:'',
            }
        },
        
        { 
            nombre: 'mecanicos', 
            url: `${process.env.REACT_APP_API_URL}/mecanico.php?mecanicos`,
            campos: {
                nombMecanica:'',
                mecanicoACargo:'',
                especialida:'',
                direccion:'',
                facebook:'',
                whatsapp:'',
                telefono:'',
                comoLlegar:'',
                imgMecanico:'',
            }
        },

        { 
            nombre: 'general_mecanico', 
            url: `${process.env.REACT_APP_API_URL}/mecanico.php?general_mecanico`,
            campos: {
                fachada:'',
                nombreMecan:'',
                nombServicios:'',
                servicios:'',
                descripcion:'',
                telefono:'',
                whatsapp:'',
                imgGenMecanico:'',
            }
        },
        // Agrega más objetos para cada tabla que desees mostrar
    ];

    useEffect(() => {
        if (tablaSeleccionada) {
            // Realizar la solicitud GET al servidor Express para obtener los datos de la tabla seleccionada
            axios.get(tablaSeleccionada.url)
                .then(response => {
                    // Establecer los datos de la tabla en el estado
                    setDatosTabla(response.data);
                })
                .catch(error => {
                    console.error(`Error al obtener datos de ${tablaSeleccionada.nombre}:`, error);
                });
        }
    }, [tablaSeleccionada]);

    const handleRegister = () => {
        // Actualizar la lista de usuarios después de un registro exitoso
        axios.get(tablaSeleccionada.url)
            .then(response => {
                setDatosTabla(response.data);
                // Ocultar el formulario de registro después de un registro exitoso
                setShowRegistro(false);
            })
            .catch(error => {
                console.error(`Error al obtener datos de ${tablaSeleccionada.nombre}:`, error);
            });
    };

    const handleEdit = (userId) => {
        setEditingUserId(userId);
    };

    const handleCancelEdit = () => {
        setEditingUserId(null);
    };

    const handleDelete = (elementId) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/eliminar.php?tabla=${tablaSeleccionada.nombre}&id=${elementId}`)
            .then(response => {
                console.log("Elemento eliminado:", response.data);
                // Recargar la página después de eliminar exitosamente
                window.location.reload();
            })
            .catch(error => {
                console.error(`Error al eliminar elemento de ${tablaSeleccionada.nombre}:`, error);
            });
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    };
    
    return (
        <div className="container">
            <h1>Lista de Tablas</h1> 
            <button className="logout-button" onClick={handleLogout}>Cerrar Sesión</button>
            <select
                value={tablaSeleccionada ? tablaSeleccionada.nombre : ''}
                onChange={(e) => {
                    const tabla = tablas.find(t => t.nombre === e.target.value);
                    setTablaSeleccionada(tabla);
                }}
                className="tabla-selector"
            >
                <option value="" className="tabla-option">Seleccione una tabla</option>
                {tablas.map((tabla, index) => (
                    <option key={index} value={tabla.nombre} className="tabla-option">{tabla.nombre}</option>
                ))}
            </select>
            {showRegistro && (
                <RegistroUsuario
                    tablaSeleccionada={tablaSeleccionada}
                    campos={tablaSeleccionada.campos}
                    onRegister={handleRegister}
                    onCancel={() => setShowRegistro(false)}
                />
            )}
            {!showRegistro ? (
                <button onClick={() => setShowRegistro(true)} className="registro-button">Registrar Elemento</button>
            ) : null}
            
            {editingUserId && (
                <div className="edit-container-overlay">
                    <EditarUsuario
                        tabla={tablaSeleccionada.nombre}
                        elemento={datosTabla.find(elemento => elemento.id === editingUserId)}
                        onCancel={handleCancelEdit}
                    />
                </div>
            )}
            
            {tablaSeleccionada && (
                <table>
                    <thead>
                        <tr>
                            {/* Renderizar los encabezados de la tabla seleccionada */}
                            {datosTabla && datosTabla.length > 0 && Object.keys(datosTabla[0]).map(columna => (
                                <th key={columna}>{columna}</th>
                            ))}
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Renderizar el contenido de la tabla seleccionada */}
                        {datosTabla.map((elemento, index) => (
                            <tr key={index}>
                                {Object.values(elemento).map((valor, index) => (
                                    <td key={index}>{valor}</td>
                                ))}
                                <td>
                                    {editingUserId === elemento.id ? (
                                        <EditarUsuario tabla={tablaSeleccionada.nombre} elemento={elemento} onCancel={handleCancelEdit} />
                                    ) : (
                                        <>
                                            <button onClick={() => handleEdit(elemento.id)} className="editar-button">Editar</button>
                                            <button onClick={() => handleDelete(elemento.id)} className="eliminar-button">Eliminar</button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
    

}

export default ListaUsuarios;
