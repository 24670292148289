import React, { useState, useEffect } from 'react';
import Navbar from '../../componentes/navbar.js';
import Slider from '../../componentes/slider.js';
import { MapaCarwash} from '../../componentes/map.js';
import Footer from '../../componentes/footer.js';
import CustomizedDialogs from './modalCarWash.js';
import GlobalSearch from '../../componentes/GlobalSearch.js';
import '../../hojas-css/miniban.css';

function CarWash() {
    const [data, setData] = useState([]);
    const [selectedMovil, setSelectedMovil] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/carwash.php?carwash`)
        .then(response => response.json())
        .then(data => {
            setData(data); 
        })
        .catch(error => console.error('Error al obtener los datos:', error));
    }, []);
    
    const handleClickVerMas = (CarWa) => {
        setSelectedMovil(CarWa);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const filteredData = data.filter(CarWa =>
        CarWa.nombCarwash.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="App">
            
            <div className='navbar-container'>
                <Navbar />
            </div>

            <div className='slider-container'>
                <Slider />
            </div>
            <GlobalSearch searchTerm={searchTerm} onSearchChange={setSearchTerm} />
            <div className='contenedor-padre'>
                <div className='contenedor-hijo2'>
                    
                        
                        <div className='miniban-container'>
                            <div className="column-container">
                                <h1> Car Wash </h1>
                                {filteredData.map((CarWa, index) => (
                                    <div key={index} className="miniban-container">
                                        <img src={CarWa.imgCarwash} alt="Imagen de promo" className="miniban-image" />
                                        <div className="miniban-info">
                                            <h2>{CarWa.nombCarwash}</h2>
                                            <p>{CarWa.descripcion}</p>
                                            <p><i className="fa-solid fa-location-dot"></i> Direccion: {CarWa.direccion}</p>
                                            {CarWa.sitoWeb ? (
                                                <div className="link-item">
                                                <a href={CarWa.sitoWeb} style={{ color: 'blue' }}><i className="fa-solid fa-globe"></i> sitio Web</a>
                                                </div>
                                            ):null}
                                            {CarWa.facebook ? (
                                                <div className="link-item">
                                                <a href={CarWa.facebook} style={{ color: 'blue' }}><i className="fab fa-facebook"></i> Facebook</a>
                                                </div>
                                            ):null}
                                            
                                            <div className='iconos-horizontal'>
                                            {CarWa.whatsapp ? (
                                                <div className="link-item">
                                                <a href={CarWa.whatsapp} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> whatsapp</a>
                                                </div>
                                            ):null}
                                            {CarWa.telefono ? (
                                                <div className="link-item">
                                                <a href={CarWa.telefono} style={{ color: '#0088cc' }}><i className="fa-solid fa-phone"></i>Resevaciones</a>
                                                </div>
                                            ):null}
                                            {CarWa.comoLlegar ? (
                                                <div className="link-item">
                                                <a href={CarWa.comoLlegar} style={{ color: '#666' }}><i className="fa-solid fa-car"></i> Como Llegar</a>
                                                </div>
                                            ):null}
                                            </div>

                                            
                                            <button onClick={() => handleClickVerMas(CarWa)} className="ver-mas-btn">Ver {CarWa.nombCarwash}</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            
                            <CustomizedDialogs 
                                open={isModalOpen}
                                handleClose={handleCloseModal}
                                CarWa={selectedMovil}
                            />

                        </div>
                </div>
                <div className='mapa-container'>
                    <MapaCarwash searchTerm={searchTerm} />
                </div>
            </div>

            <div className='footer-container'>
                <Footer />
            </div>
            
        </div>
    );
}

export default CarWash;
