import React, { useState, useEffect } from 'react';
import Navbar from '../../componentes/navbar.js';
import Slider from '../../componentes/slider.js';
import { MapaCocheras} from '../../componentes/map.js';
import Footer from '../../componentes/footer.js';
import CustomizedDialogs from './modalCochera.js';
import GlobalSearch from '../../componentes/GlobalSearch.js';
import '../../hojas-css/miniban.css';

function Cochera() {
    const [data, setData] = useState([]);
    const [selectedMovil, setSelectedMovil] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/cocheras.php?cochera`)
        .then(response => response.json())
        .then(data => {
            setData(data); 
        })
        .catch(error => console.error('Error al obtener los datos:', error));
    }, []);
    

    const handleClickVerMas = (coch) => {
        setSelectedMovil(coch);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const filteredData = data.filter(coch =>
        coch.nombCoch.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="App">
            
            <div className='navbar-container'>
                <Navbar />
            </div>

            <div className='slider-container'>
                <Slider />
            </div>
            <GlobalSearch searchTerm={searchTerm} onSearchChange={setSearchTerm} />
            <div className='contenedor-padre'> 
                <div className='contenedor-hijo2'>
                        <div className='miniban-container'>
                            <div className="column-container">
                                <h1> Cocheras </h1>
                                {filteredData.map((coch, index) => (
                                    <div key={index} className="miniban-container">
                                        <img src={coch.imgCoch} alt="Imagen de promo" className="miniban-image" />
                                        <div className="miniban-info">
                                            <h2>{coch.nombCoch}</h2>
                                            {coch.descripcion&& (
                                                <p >
                                                    {coch.descripcion}
                                                    </p>
                                            )}
                                            <p><i className="fa-solid fa-location-dot"></i> Direccion: {coch.direccion}</p>
                                            {coch.sitoWeb ? (
                                                <div className="link-item">
                                                <a href={coch.sitoWeb} style={{ color: 'blue' }}><i className="fa-solid fa-globe"></i> sitio Web</a>
                                                </div>
                                            ):null}
                                            {coch.facebook ? (
                                                <div className="link-item">
                                                <a href={coch.facebook} style={{ color: 'blue' }}><i className="fab fa-facebook"></i> Facebook</a>
                                                </div>
                                            ):null}
                                            {coch.cochera === 'si' ? (
                                            <p>
                                                <i class="fa-solid fa-warehouse"></i> Cochera 
                                            </p>
                                            ):null}
                                            <div className='iconos-horizontal'>
                                            {coch.whatsapp ? (
                                                <div className="link-item">
                                                <a href={coch.whatsapp} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> whatsapp</a>
                                                </div>
                                            ):null}
                                            {coch.telefono ? (
                                                <div className="link-item">
                                                <a href={coch.telefono} style={{ color: '#0088cc' }}><i className="fa-solid fa-phone"></i> Resevaciones</a>
                                                </div>
                                            ):null}
                                            {coch.comoLlegar ? (
                                                <div className="link-item">
                                                <a href={coch.comoLlegar} style={{ color: '#666' }}><i className="fa-solid fa-car"></i> Como Llegar</a>
                                                </div>
                                            ):null}
                                            </div>

                                            
                                            <button onClick={() => handleClickVerMas(coch)} className="ver-mas-btn">Ver {coch.nombCoch}</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            
                            <CustomizedDialogs 
                                open={isModalOpen}
                                handleClose={handleCloseModal}
                                coch={selectedMovil}
                            />

                        </div>
                </div>
                <div className='mapa-container'>
                    <MapaCocheras searchTerm={searchTerm} />
                </div>
            </div>
            
            <div className='footer-container'>
                <Footer />
            </div>
            
        </div>
    );
}

export default Cochera;
