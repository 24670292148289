import React, { useState, useEffect } from 'react';
import Navbar from '../../componentes/navbar.js';
import Slider from '../../componentes/slider.js';
import { MapaTaxi} from '../../componentes/map.js';
import { BotonMovilidad} from '../../componentes/botones2.js';
import Footer from '../../componentes/footer.js';
import CustomizedDialogs from '../../componentes/modal.js'; 
import GlobalSearch from '../../componentes/GlobalSearch.js';
import '../../hojas-css/miniban.css';


function Mobilidad() {
    const [data, setData] = useState([]);
    const [selectedMovil, setSelectedMovil] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/movilidad.php?movilidad`)
        .then((response) => response.json())
        .then((data) => {
            setData(data.filter(item => item.id_roles === 2));
        })
        .catch(error => console.error('Error al obtener los datos:', error));
    }, []);

    const handleClickVerMas = (movil) => {
        setSelectedMovil(movil);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const filteredData = data.filter(movil =>
        movil.nombEmp.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="App">
            <div className='navbar-container'>
                <Navbar />
            </div>

            <div className='slider-container'>
                <Slider />
            </div>
            <h1 className="titulo-2">MOVILIDAD</h1>
            <GlobalSearch searchTerm={searchTerm} onSearchChange={setSearchTerm} />
            <div className='contenedor-padre2'>
                <div className='contenedor-hijo'>
                    
                    <div className='botones-container'>
                    <BotonMovilidad />
                    </div>
                    
                    <div className='miniban-container'>
                        <div className="column-container">
                            <h1>Taxis</h1>
                            {filteredData.map((movil, index) => (
                            <div key={index} className="miniban-container">
                                <img src={movil.imagenMovilidad} alt="Imagen de promo" className="miniban-image" />
                                <div className="miniban-info">
                                <h2>{movil.nombEmp}</h2>
                                            {movil.descripcion && (
                                                    <p style={{ color: '#F5F5F5' }}>
                                                        {movil.descripcion}
                                                        </p>
                                                )}
                                            <p style={{ color: '#336699' }}><i className="fa-solid fa-location-dot"> </i> Direccion: {movil.direccion}</p>
                                            {movil.ruta && (
                                                <p style={{ color: '#c0bebe' }}>
                                                    <i className="fa-solid fa-route"></i> Ruta: {movil.ruta}
                                                </p>
                                            )}
                                                
                                            <div className='iconos-horizontal'>
                                                    {movil.facebook ? (
                                                        <div className="link-item">
                                                            <a href={movil.facebook} style={{ color: 'blue' }}>
                                                            <i className="fab fa-facebook"></i> Facebook</a>
                                                        </div>
                                                    ): null}
                                                    
                                                    {movil.wasap ? (
                                                        <div className="link-item">
                                                            <a href={movil.wasap} style={{ color: 'green' }}>
                                                            <i className="fa-brands fa-whatsapp"></i> whatsapp</a>
                                                        </div>
                                                    ): null}
                                                    {movil.telefono ? (
                                                        <div className="link-item">
                                                            <a href={movil.telefono} style={{ color: '#ADD8E6' }}>
                                                            <i className="fa-solid fa-phone"></i> Pedidos
                                                            </a>
                                                        </div>
                                                    ) : null}
                                                    {movil.comoLlegar ? (
                                                        <div className="link-item">
                                                            <a href={movil.comoLlegar} style={{ color: '#c0bebe' }}>
                                                            <i className="fa-solid fa-car"></i> Como Llegar</a>
                                                        </div>
                                                    ): null}
                                </div>
                                <button onClick={() => handleClickVerMas(movil)} className="ver-mas-btn">Ver Conductores</button>
                                </div>
                            </div>
                            ))}
                        </div>
                    
                    <CustomizedDialogs 
                        open={isModalOpen}
                        handleClose={handleCloseModal}
                        movil={selectedMovil}
                    />
                    </div>
                </div>
            <div className='mapa-container'>
                <MapaTaxi searchTerm={searchTerm} />
            </div>
            </div>
                
            <div className='footer-container'>
                    <Footer />
            </div>
            
        </div>
    );
}

export default Mobilidad;
