import React from 'react';
import Navbar from '../componentes/navbar.js';
import Slider from '../componentes/slider.js';
import { BotonPrincipal} from '../componentes/botones2.js';
import Footer from '../componentes/footer.js';
import '../hojas-css/principal.css'


function Principal() {

    return (
        <div className="App">
            
            <div className='navbar-container'>
                <Navbar />
            </div>

            <div className='slider-container'>
                <Slider />
            </div>

            <div className='botones-container'>
                <BotonPrincipal />
            </div>

            <div className='footer-container'>
                <Footer />
            </div>
            
        </div>
    );
}

export default Principal;
