import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import '../../hojas-css/modal.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CustomizedDialogs({ open, handleClose, hotel }) {
    const [empleados, setEmpleados] = React.useState([]);
    const [nombreMecan, setNombEmpresa] = React.useState('');

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/mecanico.php?nombreMecan=${hotel.nombMecanica}`);
                //console.log("Respuesta del servidor:", response.data);
                setEmpleados(response.data.data);
                setNombEmpresa(response.data.data.length > 0 ? response.data.data[0].nombreMecan : '');
            } catch (error) {
                console.error("Error al obtener los datos:", error);
            }
        };

        if (open && hotel) {
            fetchData();
        }
    }, [open, hotel]);

    const formatDescription = (servicios) => {
        return servicios
            .split(',')
            .map(linea => linea.trim())
            .filter(linea => linea.length > 0) // Filtra líneas vacías
            .map((linea, index) => (
                <span key={index} className="linea-con-guion">
                    - {linea}
                </span>
            ));
    };
    
    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <div className="dialog-header">
                <DialogTitle sx={{ m: 0, p: 2 }} variant="h4" className="empleado-dialog-title">
                    {nombreMecan ? `${nombreMecan} ` : 'Error al buscar'}
                </DialogTitle>
                <div className="close-button-container">
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            <DialogContent dividers className="dialog-content-custom">
                {Array.isArray(empleados) && empleados.length > 0 ? (
                    <>
                        {empleados.filter(emp => emp.fachada === 'si').map((emp, index) => (
                            <div className="empleado-container" key={index}>
                                <div className='fachada-container'>
                                    <img src={`${process.env.REACT_APP_API_URL}/imagenes/${emp.imgGenMecanico}`} alt="Imagen Fachada" className='imgFachada-image' style={{ width: '100%' }} />
                                </div>
                            </div>
                        ))}
                        {empleados.filter(emp => emp.fachada !== 'si').map((emp, index) => (
                            <div className="empleado-container" key={index}>
                                <div className='padre-conteiner'>
                                    <div className='imagen-container'>
                                        <img src={`${process.env.REACT_APP_API_URL}/imagenes/${emp.imgGenMecanico}`} alt="Imagen General" className='imgGeneral-image'/>
                                    </div>
                                    <div className='madre-container'>
                                        <Typography variant="h5" gutterBottom>
                                            <div className="empleado-title">
                                                {emp.nombServicios}
                                            </div>
                                            <div className='empleado-info3' >
                                                {emp.descripcion !== 'no' && (
                                                    <p>
                                                    {emp.descripcion}
                                                    </p>
                                                )}
                                                <p className="descripcion-texto">
                                                        {formatDescription(emp.servicios)}
                                                    </p>
                                            </div>
                                        </Typography>
                                        
                                        <div className='datos-normal'>
                                            {emp.whatsapp && (
                                                <div className="datos-item">
                                                    <a href={emp.whatsapp} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> WhatsApp</a>
                                                </div>
                                            )}
                                            {emp.telefono && (
                                                <div className="datos-item">
                                                    <a href={emp.telefono} style={{ color: '#0088cc' }}><i className="fa-solid fa-phone"></i> telefono</a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                    <div className='datos-resposive'>
                                        {emp.whatsapp && (
                                            <div className="datos-item">
                                                <a href={emp.whatsapp} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> WhatsApp</a>
                                            </div>
                                        )}
                                        {emp.telefono && (
                                            <div className="datos-item">
                                                <a href={emp.telefono} style={{ color: '#0088cc' }}><i className="fa-solid fa-phone"></i> telefono</a>
                                            </div>
                                        )}
                                    </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <Typography variant="body1">
                        No se encontraron datos del hotel.
                    </Typography>
                )}
            </DialogContent>
        </BootstrapDialog>
    );
}
