import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import '../hojas-css/modal.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs({ open, handleClose, movil }) {
  const [empleados, setEmpleados] = React.useState([]);
  const [nombEmpresa, setNombEmpresa] = React.useState('');

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/movilidad.php?nombEmpresa=${movil.nombEmp}`);
        //console.log("Respuesta del servidor:", response.data);
        setEmpleados(response.data.data);
        setNombEmpresa(response.data.data.length > 0 ? response.data.data[0].nombEmpresa : '');
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    if (open && movil) {
      fetchData();
    }
  }, [open, movil]);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <div className="dialog-header">
        <DialogTitle sx={{ m: 0, p: 2 }} variant="h4" className="empleado-dialog-title">
          {nombEmpresa ? `${nombEmpresa}` : 'Conductores disponibles'}
        </DialogTitle>
        <div className="close-button-container">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <DialogContent dividers className="dialog-content-custom">
        {Array.isArray(empleados) && empleados.length > 0 ? (
          <>
            {empleados.filter(emp => emp.fachada === 'si').map((emp, index) => (
              <div className="empleado-container" key={index}>
                <div className='fachada-container'>
                  <img src={`${process.env.REACT_APP_API_URL}/imagenes/${emp.imgGeneral}`} alt="Imagen General" className='imgGeneral-image' style={{ width: '100%' }} />
                </div>
              </div>
            ))}
            {empleados.filter(emp => emp.fachada !== 'si').map((emp, index) => (
              <div className="empleado-container" key={index}>
                <div className='padre-conteiner'>
                  <div className='imagen-container'>
                    <img src={`${process.env.REACT_APP_API_URL}/imagenes/${emp.imgGeneral}`} alt="Imagen General" className='imgGeneral-image'/>
                  </div>
                  <div className='madre-container'>
                    <Typography variant="h6" className="empleado-title" gutterBottom>
                    <div className="empleado-title">
                      {emp.nombre} {emp.apellidos}
                    </div>
                    </Typography>
                    <div className="empleado-info">
                      <p>DNI:</p><p>{emp.dni}</p>
                      <p>Licencia:</p><p>{emp.nroLicencia}</p>
                      <p>Clase:</p><p>{emp.clase}</p>
                      <p>Matricula:</p><p>{emp.matricula}</p>
                    </div>
                    <div className='datos-normal'>
                      {emp.numeroTelefono && (
                        <div className="datos-item">
                          <a href={`tel:${emp.numeroTelefono}`} style={{ color: '#333' }}><i className="fa-solid fa-phone"></i> Teléfono</a>
                        </div>
                      )}
                      {emp.whatsapp && (
                        <div className="datos-item">
                          <a href={`https://wa.me/${emp.whatsapp}`} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> WhatsApp</a>
                        </div>
                      )}
                      {emp.facebook && (
                        <div className="datos-item">
                          <a href={emp.facebook} style={{ color: 'blue' }}><i className="fab fa-facebook"></i> Facebook</a>
                        </div>
                      )}
                    </div>
                  </div>
                  
                </div>
                    <div className='datos-resposive'>
                      {emp.numeroTelefono && (
                        <div className="datos-item">
                          <a href={`tel:${emp.numeroTelefono}`} style={{ color: '#333' }}><i className="fa-solid fa-phone"></i> Teléfono</a>
                        </div>
                      )}
                      {emp.whatsapp && (
                        <div className="datos-item">
                          <a href={`https://wa.me/${emp.whatsapp}`} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> WhatsApp</a>
                        </div>
                      )}
                      {emp.facebook && (
                        <div className="datos-item">
                          <a href={emp.facebook} style={{ color: 'blue' }}><i className="fab fa-facebook"></i> Facebook</a>
                        </div>
                      )}
                    </div>
              </div>
            ))}
          </>
        ) : (
          <Typography variant="body1">
            No se encontraron Conductores para esta empresa.
          </Typography>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
}
