import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin  } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import './estilos.css'



function Registro() {
    //const [correo, setCorreo] = useState('');
   // const [contraseña, setContraseña] = useState('');
    const [isRegister, setIsRegister] = useState(false);
    const [values, setValues] = useState({
        nombre: '',
        correo: '',
        usuario: '',
        contraseña: '',
    });
    const navigate = useNavigate();
    const [isWelcomeModal, setIsWelcomeModal] = useState(false);
    const [showModal, setShowModal] = useState(false); // Para controlar la visibilidad del modal
    const [modalMessage, setModalMessage] = useState(''); // Mensaje del modal


   /* useEffect(() => {
        // Verificar el estado de inicio de sesión al cargar el componente
        const isAuthenticated = localStorage.getItem('token'); // Verifica si hay un token u otra información

        if (isAuthenticated) {
            // Redirigir al usuario a la página principal o a la página deseada
            navigate('/principal');
        }
    }, [navigate]);*/

    

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const validate = () => {
        let errors = {};
    
        if (isRegister) {
            if (!values.nombre.trim()) {
                errors.nombre = 'El nombre es obligatorio';
            }
            if (!values.correo.trim()) {
                errors.correo = 'El correo es obligatorio';
            }
            if (!values.usuario.trim()) {
                errors.usuario = 'El usuario es obligatorio';
            }
            if (!values.contraseña.trim()) {
                errors.contraseña = 'La contraseña es obligatoria';
            }
        } else {
            if (!values.correo.trim()) {
                errors.correo = 'El correo es obligatorio para iniciar sesión';
            }
            if (!values.contraseña.trim()) {
                errors.contraseña = 'La contraseña es obligatoria para iniciar sesión';
            }
        }
    
        return errors;
    };

    
    
    const handleSuccessfulLogin = (credentialResponse, userRole) => {
        console.log('Respuesta de Google completa:', credentialResponse);
    
        // Actualiza cómo accedes al nombre y al correo en la respuesta de Google
        const nombre = credentialResponse?.profileObj?.givenName || 'Nombre Predeterminado';
        const correo = credentialResponse?.profileObj?.email || 'Correo Predeterminado';
    
        console.log('Nombre y Correo:', nombre, correo);
        console.log('Rol del usuario:', userRole);
    
        axios.post(`${process.env.REACT_APP_API_URL}/datosGoogle.php`, {
            nombre: nombre,
            correo: correo,
        })
        .then(response => {
            console.log('Datos guardados en la base de datos:', response.data);
    
            // Manejar la respuesta del servidor aquí
            console.log('Respuesta del servidor:', response.data);
    
            if (response.data.message === 'Usuario ya registrado') {
                setModalMessage("¡Bienvenido de nuevo!");
            } else if (response.data.message === 'Nuevo usuario creado') {
                setModalMessage("¡Registro exitoso!");
            } else if (response.data.message === 'Usuario existente actualizado') {
                setModalMessage("¡Bienvenido de nuevo! Tus datos se han actualizado.");
            }
            setShowModal(true);
    
            // Redirigir al usuario después de manejar la respuesta del servidor
            setTimeout(() => {
                setShowModal(false);
                setIsWelcomeModal(false);
                if (userRole === 'administrador') {
                    navigate('/crud');
                } else {
                    navigate('/');
                }
            }, 2000);
        })
        .catch(error => {
            console.error('Error al guardar datos en la base de datos:', error);
            setModalMessage('Error al procesar los datos de Google. Intente de nuevo.');
            setShowModal(true);
        });
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        let formErrors = validate();
        

        if (Object.keys(formErrors).length === 0) {
            if (isRegister) {
                axios.post(`${process.env.REACT_APP_API_URL}/usuarios.php`, values)
                    .then(res => {
                        console.log("Registro exitoso:", res);
                        setModalMessage("¡Registro exitoso!");
                        handleSuccessfulLogin();
                        setShowModal(true);
                        setValues({
                            nombre: '',
                            correo: '',
                            usuario: '',
                            contraseña: '',
                        });
                    })
                    .catch(err => {
                        if (err.response && err.response.data && err.response.data.error) {
                            setModalMessage(err.response.data.error);
                            setShowModal(true);
                        } else {
                            console.error('Error al registrar:', err);
                            setModalMessage('Error al registrar. Intente de nuevo.');
                            setShowModal(true);
                        }
                    });
            } else {
                axios.post(`${process.env.REACT_APP_API_URL}/login.php`, {
                correo: values.correo,
                contraseña: values.contraseña
            })
            
            .then(res => {
               //localStorage.setItem('token', 'miToken'); // Agrega un valor al token, puede ser el token real obtenido del servidor
               const userRole = res.data.user.rol;
               setModalMessage("¡Bienvenido! Has iniciado sesión exitosamente.");
               handleSuccessfulLogin(res, userRole);

            })
            
                .catch(error => {
                    if (error.response) {
                        console.error("Error:", error.response.data.error);
                        alert(error.response.data.error);
                    } else if (error.request) {
                        console.error("Error de red o servidor:", error.request);
                        alert("Error de red o servidor.");
                    } else {
                        console.error("Error:", error.message);
                        alert("Error al procesar la solicitud.");
                    }
                });
            }
            }else {
                setModalMessage("completa todos los campos requeridos.");
                setShowModal(true);
    
        }
    };

    const toggleSection = () => {
        setIsRegister(!isRegister);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <main className="contenedor__todo">
            <div className="contenedor__login-register">
                <form className={isRegister ? "formulario__register" : "formulario__login"} onSubmit={handleSubmit}>
                    <h2>{isRegister ? "Regístrarse" : "Iniciar Sesión"}</h2>

                    {!isRegister && (
                        <>
                            <input type="email" placeholder="Correo" name="correo" value={values.correo} onChange={handleChange} />
                            
                            <input type="password" placeholder="Contraseña" name="contraseña" value={values.contraseña} onChange={handleChange} />
                            
                        </>
                    )}
                    {isRegister && (
                        <>
                            <input type="text" placeholder="Nombre" name="nombre" onChange={handleChange} />
                            <input type="email" placeholder="Correo Electrónico" name="correo" onChange={handleChange} />
                            <input type="text" placeholder="Usuario" name="usuario" onChange={handleChange} />
                            <input type="password" placeholder="Contraseña" name="contraseña" onChange={handleChange} />
                        </>
                    )}

                    <button type="submit">{isRegister ? "Regístrarse" : "Iniciar Sesión"}</button>
                    {!isRegister && (
                    <GoogleOAuthProvider 
                    clientId="15703480591-2kgiiot59kutvbmmdlimnlicajvsp0on.apps.googleusercontent.com"
                    >
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                console.log('Respuesta de Google:', credentialResponse);
                                const decoded = jwtDecode(credentialResponse.credential);
                                console.log('Respuesta decodificada:', decoded);
                                handleSuccessfulLogin(decoded);
                            }}
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />
                    </GoogleOAuthProvider>
                    )}
                </form>
                <div className="toggle-section">
                    <p>{isRegister ? "¿Ya tienes una cuenta?" : "¿Aún no tienes una cuenta?"}</p>
                    <button onClick={toggleSection}>{isRegister ? "Iniciar Sesión" : "Regístrarse"}</button>
                </div>
            </div>
            {showModal && (
                <div className="modal-overlay">
                <div className="modal">
                    {isWelcomeModal ? (
                        <p>{modalMessage}</p>
                    ) : (
                        <>
                            <p>{modalMessage}</p>
                            <button onClick={closeModal}>Cerrar</button>
                        </>
                    )}
                </div>
            </div>
            )}
        </main>
    );
}

export default Registro;
