import React from 'react';
import construImage from '../imagenes/constru.png'; // Importa la imagen
import '../hojas-css/crud.css';

const Trabajando = () => {
    return (
        <div className="trabajando-container">
            <img src={construImage} alt="Página en construcción" className="trabajando-image" />
            <h1 className="trabajando-text">Estamos trabajando en la página web</h1>
        </div>
    );
};

export default Trabajando;
