import React, { useState, useEffect } from 'react';
import Navbar from '../../componentes/navbar.js';
import Slider from '../../componentes/slider.js';
import { MapaMecanicos} from '../../componentes/map.js';
import Footer from '../../componentes/footer.js';
import CustomizedDialogs from './modalMecanico.js';
import GlobalSearch from '../../componentes/GlobalSearch.js';
import '../../hojas-css/miniban.css';

function Mecanicos() {
    const [data, setData] = useState([]);
    const [selectedMovil, setSelectedMovil] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/mecanico.php?mecanicos`)
        .then(response => response.json())
        .then(data => {
            setData(data); 
        })
        .catch(error => console.error('Error al obtener los datos:', error));
    }, []);
    

    const handleClickVerMas = (hotel) => {
        setSelectedMovil(hotel);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const filteredData = data.filter(hotel =>
        hotel.nombMecanica.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const formatDescription = (especialida) => {
        return especialida.split(',').map((linea, index) => (
            <span key={index} className="linea-con-guion">
                - {linea.trim()}
            </span>
        ));
    };
    return (
        <div className="App">
            
            <div className='navbar-container'>
                <Navbar />
            </div>

            <div className='slider-container'>
                <Slider />
            </div>
            <GlobalSearch searchTerm={searchTerm} onSearchChange={setSearchTerm} />
            <div className='contenedor-padre'> 
                <div className='contenedor-hijo2'>
                        <div className='miniban-container'>
                            <div className="column-container">
                                <h1> Mecanicos </h1>
                                {filteredData.map((hotel, index) => (
                                    <div key={index} className="miniban-container">
                                        <img src={hotel.imgMecanico} alt="Imagen de promo" className="miniban-image" />
                                        <div className="miniban-info">
                                            <h2>{hotel.nombMecanica}</h2>
                                            {hotel.mecanicoACargo && (                                               
                                                <p><span className="font-bold">Mecanico:</span> {hotel.mecanicoACargo}</p>                                                  
                                            )}
                                            <p><i className="fa-solid fa-location-dot"></i> <span className="font-bold">Direccion:</span> {hotel.direccion}</p>
                                            <p><span className="font-bold">Especialidad:</span>{formatDescription(hotel.especialida)} </p> 

                                            {hotel.facebook && (
                                                <div className="link-item">
                                                <a href={hotel.facebook} style={{ color: 'blue' }}><i className="fab fa-facebook"></i> Facebook</a>
                                                </div>
                                            )}
                                            <div className='iconos-horizontal'>
                                            {hotel.whatsapp && (
                                                <div className="link-item">
                                                <a href={hotel.whatsapp} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> whatsapp</a>
                                                </div>
                                            )}
                                            {hotel.telefono && (
                                                <div className="link-item">
                                                <a href={hotel.telefono} style={{ color: '#0088cc' }}><i className="fa-solid fa-phone"></i> telefono</a>
                                                </div>
                                            )}
                                            {hotel.comoLlegar && (
                                                <div className="link-item">
                                                <a href={hotel.comoLlegar} style={{ color: '#666' }}><i className="fa-solid fa-car"></i> Como Llegar</a>
                                                </div>
                                            )}
                                            </div>

                                            
                                            <button onClick={() => handleClickVerMas(hotel)} className="ver-mas-btn">mas de {hotel.nombMecanica}</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            
                            <CustomizedDialogs 
                                open={isModalOpen}
                                handleClose={handleCloseModal}
                                hotel={selectedMovil}
                            />

                        </div>
                </div>
                <div className='mapa-container'>
                    <MapaMecanicos searchTerm={searchTerm} />
                </div>
            </div>

            <div className='footer-container'>
                <Footer />
            </div>
            
        </div>
    );
}

export default Mecanicos;
