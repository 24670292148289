import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import minivan from '../imagenes/minivan.png';
import mototaxi from '../imagenes/mototaxi.png';
import taxi from '../imagenes/taxi.png';
import combi from '../imagenes/combi.png';
import bus from '../imagenes/bus.png';
import pollo from '../imagenes/pollo.png';
import chifa from '../imagenes/chifa.png';
import ceviche from '../imagenes/ceviche.png';
import campestre from '../imagenes/campestre.png';
import restobar from '../imagenes/restobar.png';
import mixtos from '../imagenes/mixtos.png';
import hoteles from '../imagenes/hoteles.png';
import carwash from '../imagenes/carwash.png';
import futbol from '../imagenes/futbol.png';
import clinica from '../imagenes/clinica.png';
import cochera from '../imagenes/cochera.png';
import mecanico from '../imagenes/mecanico.png'; // Asegúrate de tener el ícono correcto
import miposicion from '../imagenes/miposicion.png'; 
import '../hojas-css/mapa.css';

const basePosition = [-11.496548511664544, -77.20925204101961];

const createMarkerIcon = (iconUrl) => {
  return new L.Icon({
    iconUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
  });
};

// Nueva función para obtener datos de varias tablas
const fetchDataFromTables = async () => {
  try {
    const responses = await Promise.all([
      fetch(`${process.env.REACT_APP_API_URL}/mapas.php?mapa_movilidad`).then((response) => response.json()),
      fetch(`${process.env.REACT_APP_API_URL}/mapas.php?mapa_restaurante`).then((response) => response.json()),
      fetch(`${process.env.REACT_APP_API_URL}/mapas.php?mapa_hotel`).then((response) => response.json()),
      fetch(`${process.env.REACT_APP_API_URL}/mapas.php?mapa_carwash`).then((response) => response.json()),
      fetch(`${process.env.REACT_APP_API_URL}/mapas.php?mapa_campodepor`).then((response) => response.json()),
      fetch(`${process.env.REACT_APP_API_URL}/mapas.php?mapa_clinica`).then((response) => response.json()),
      fetch(`${process.env.REACT_APP_API_URL}/mapas.php?mapa_cochera`).then((response) => response.json()),
      fetch(`${process.env.REACT_APP_API_URL}/mapas.php?mapa_mecanico`).then((response) => response.json()),
    ]);

    // Unificar los datos y normalizar el campo rol_id
    const unifiedData = responses.flat().map((item) => {
      return {
        ...item,
        rol_id: item.rol_id || item.rol_restaurante || item.rol_hotel || item.rol_carwash || item.rol_campo
        || item.rol_clinica || item.rol_cochera || item.rol_mecanico, // Asumiendo que "rol_restaurante" es el nombre diferente en otra tabla
      };
    });

    return unifiedData;
  } catch (error) {
    console.error('Error al obtener los datos:', error);
    return [];
  }
};

const MapUpdater = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    if (position) {
      map.setView(position, 14); // Establece el zoom al nivel 14
    }
  }, [position, map]);

  return null;
};

const BaseMap = ({ rol_id, iconUrl, searchTerm }) => {
  const [data, setData] = useState([]);
  const [userPosition, setUserPosition] = useState(null);
  const [searchedPosition, setSearchedPosition] = useState(null);

  useEffect(() => {
    fetchDataFromTables().then(setData);
  }, []);

  useEffect(() => {
    const getUserPosition = () => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserPosition([position.coords.latitude, position.coords.longitude]);
        },
        (error) => {
          console.error('Error al obtener la posición del usuario:', error);
        }
      );
    };

    getUserPosition();
  }, []);

  useEffect(() => {
    const foundItem = data.find(item => item.nom_Emp && item.nom_Emp.toLowerCase() === searchTerm.toLowerCase());
    if (foundItem) {
      setSearchedPosition([foundItem.latitud, foundItem.longitud]);
    }
  }, [data, searchTerm]);

  const filteredData = data.filter((item) => {
    return item.rol_id === rol_id && item.nom_Emp && item.nom_Emp.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const icon = createMarkerIcon(iconUrl);
  const userIcon = createMarkerIcon(miposicion);

  return (
    <div className="map-container">
      <MapContainer center={searchedPosition || basePosition} zoom={14} className="leaflet-container">
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MapUpdater position={searchedPosition} />
        {userPosition && (
          <Marker position={userPosition} icon={userIcon}>
            <Popup>¡Estás aquí!</Popup>
          </Marker>
        )}
        {filteredData.map((item, index) => (
          <Marker key={index} position={[item.latitud, item.longitud]} icon={icon}>
            <Popup>
              <div className="map-popup">
                <span className="nom-emp">{item.nom_Emp}</span>
                <br />
                <span className="direccion">{item.direccion}</span>
                <br />
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

const MapaMiniban = ({ searchTerm }) => {
  return <BaseMap rol_id={1} iconUrl={minivan} searchTerm={searchTerm} />;
};

const MapaTaxi = ({ searchTerm }) => {
  return <BaseMap rol_id={2} iconUrl={taxi} searchTerm={searchTerm} />;
};

const MapaMototaxi = ({ searchTerm }) => {
  return <BaseMap rol_id={3} iconUrl={mototaxi} searchTerm={searchTerm} />;
};

const MapaCombi = ({ searchTerm }) => {
  return <BaseMap rol_id={4} iconUrl={combi} searchTerm={searchTerm} />;
};

const MapaBus = ({ searchTerm }) => {
  return <BaseMap rol_id={5} iconUrl={bus} searchTerm={searchTerm} />;
};

const MapaPollerias = ({ searchTerm }) => {
  return <BaseMap rol_id={6} iconUrl={pollo} searchTerm={searchTerm} />;
};

const MapaChifa = ({ searchTerm }) => {
  return <BaseMap rol_id={7} iconUrl={chifa} searchTerm={searchTerm} />;
};

const MapaCampestre = ({ searchTerm }) => {
  return <BaseMap rol_id={8} iconUrl={campestre} searchTerm={searchTerm} />;
};

const MapaRestobar = ({ searchTerm }) => {
  return <BaseMap rol_id={9} iconUrl={restobar} searchTerm={searchTerm} />;
};

const MapaMixtos = ({ searchTerm }) => {
  return <BaseMap rol_id={10} iconUrl={mixtos} searchTerm={searchTerm} />;
};

const MapaCevicheria = ({ searchTerm }) => {
  return <BaseMap rol_id={11} iconUrl={ceviche} searchTerm={searchTerm} />;
};

const MapaHotel = ({ searchTerm }) => {
  return <BaseMap rol_id={12} iconUrl={hoteles} searchTerm={searchTerm} />;
};

const MapaCarwash = ({ searchTerm }) => {
  return <BaseMap rol_id={13} iconUrl={carwash} searchTerm={searchTerm} />;
};

const MapaCampoDep = ({ searchTerm }) => {
  return <BaseMap rol_id={14} iconUrl={futbol} searchTerm={searchTerm} />;
};

const MapaClinicas = ({ searchTerm }) => {
  return <BaseMap rol_id={15} iconUrl={clinica} searchTerm={searchTerm} />;
};

const MapaPostas = ({ searchTerm }) => {
  return <BaseMap rol_id={16} iconUrl={clinica} searchTerm={searchTerm} />;
};

const MapaSeguros = ({ searchTerm }) => {
  return <BaseMap rol_id={17} iconUrl={clinica} searchTerm={searchTerm} />;
};

const MapaCocheras = ({ searchTerm }) => {
  return <BaseMap rol_id={18} iconUrl={cochera} searchTerm={searchTerm} />;
};

const MapaMecanicos = ({ searchTerm }) => {
  return <BaseMap rol_id={19} iconUrl={mecanico} searchTerm={searchTerm} />; // Asegúrate de que el ícono es el correcto
};

export { MapaMiniban, MapaTaxi, MapaMototaxi, MapaCombi, MapaBus, MapaPollerias,
        MapaChifa, MapaCampestre, MapaRestobar, MapaMixtos, MapaCevicheria, MapaHotel,
        MapaCarwash, MapaCampoDep, MapaClinicas, MapaPostas, MapaSeguros, MapaCocheras,
        MapaMecanicos
};
