import Registro from "./registro.js";
import EditarUsuarioUsuarios from "./crud/editar.js";

import ListaUsuarios from "./crud/crud.js";
import NotFound from "./componentes/NotFound";
import Principal from "./huaral/principal";
import Minivan from "./huaral/movilidad/minivan.js";
import Taxi from "./huaral/movilidad/taxi.js";
import Mototaxi from "./huaral/movilidad/mototaxi.js";
import Combi from "./huaral/movilidad/combi.js";
import Bus from "./huaral/movilidad/bus.js";
import Polleria from "./huaral/restaurantes/polleria.js";
import Chifa from "./huaral/restaurantes/chifa.js";
import Campestre from "./huaral/restaurantes/campestre.js";
import Restobar from "./huaral/restaurantes/restobar.js";
import Mixtos from "./huaral/restaurantes/mixtos.js";
import Cevicheria from "./huaral/restaurantes/cevicheria.js";
import Hotel from "./huaral/hotel/hotel.js";
import CarWash from "./huaral/carWash/carwash.js";
import Campo from "./huaral/campoDep/campo.js";
import Clinicas from "./huaral/clinicas/clinicas.js";
import Seguros from "./huaral/clinicas/seguros.js";
import Postas from "./huaral/clinicas/postas.js";
import Cocheras from "./huaral/cochera/cocheras.js";
import Trabajndo from "./componentes/trabajndo.js";
import Mecanicos from "./huaral/mecanicos/mecanicos.js";


export const routes = {
    '/registro': Registro,
    '/usuarios': EditarUsuarioUsuarios,
    '/crud': ListaUsuarios,
    '/': Principal,
    '/minivan': Minivan,
    '/taxi': Taxi,
    '/mototaxi': Mototaxi,
    '/combi': Combi,
    '/bus': Bus,
    '/polleria': Polleria,
    '/chifa': Chifa,
    '/campestre': Campestre,
    '/restobar': Restobar,
    '/mixtos': Mixtos,
    '/cevicheria': Cevicheria,
    '/hotel': Hotel,
    '/carwash': CarWash,
    '/campo': Campo,
    '/clinicas': Clinicas,
    '/seguros': Seguros,
    '/postas': Postas,
    '/cocheras': Cocheras,
    '/trabajndo': Trabajndo,
    '/mecanicos': Mecanicos,
    '*': NotFound, // catch-all route
};