import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import '../../hojas-css/modal.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CustomizedDialogs({ open, handleClose, deporte }) {
    const [empleados, setEmpleados] = React.useState([]);
    const [nombCampo, setNombEmpresa] = React.useState('');

    React.useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/campodeportivo.php?nombCampo=${deporte.nombComplejo}`);
            //console.log("Respuesta del servidor:", response.data);
            setEmpleados(response.data.data);
            setNombEmpresa(response.data.data.length > 0 ? response.data.data[0].nombCampo : '');
        } catch (error) {
            console.error("Error al obtener los datos:", error);
        }
        };

        if (open && deporte) {
        fetchData();
        }
    }, [open, deporte]);

    return (
        <BootstrapDialog 
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
    >
        <div className="dialog-header">
        <DialogTitle sx={{ m: 0, p: 2 }} variant="h4" className="empleado-dialog-title">
            {nombCampo ? `${nombCampo} ` : 'Error Al buscar'}
        </DialogTitle>
            <div className="close-button-container">
                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
                </IconButton>
            </div>
        </div>
        <DialogContent dividers className="dialog-content-custom">
            {Array.isArray(empleados) && empleados.length > 0 ? (
                <>
                        {empleados.filter(emp => emp.fachada === 'si').map((emp, index) => (
                            <div className="empleado-container" key={index}>
                                <div className='fachada-container'>
                                    <img src={`${process.env.REACT_APP_API_URL}/imagenes/${emp.imgCancha}`} alt="Imagen Fachada" className='imgFachada-image' style={{ width: '100%' }} />
                                </div>
                            </div>
                        ))}
                        {empleados.filter(emp => emp.fachada !== 'si').map((emp, index) => (
                            <div className="empleado-container" key={index}>
                                <div className='padre-conteiner'>
                                    <div className='imagen-container'>
                                        <img src={`${process.env.REACT_APP_API_URL}/imagenes/${emp.imgCancha}`} alt="Imagen General" className='imgGeneral-image'/>
                                    </div>
                    <div className='madre-container'>
                    <Typography variant="h5"  gutterBottom>
                        <div className="empleado-title">
                            {emp.nombCacha}
                        </div>
                        <div className='empleado-info3' >
                            {emp.jugadores !== 'no' && (
                                <p>
                                    <i className="fa-solid fa-user-friends"></i><span className="font-modal">Nro jugadores:</span>  {emp.jugadores} vs {emp.jugadores}
                                </p>
                            )}
                            {emp.tipoPiso !== 'no' && (
                                <p>
                                    <i className="fa-solid fa-map"></i><span className="font-modal">Tipo de piso:</span> {emp.tipoPiso}
                                </p>
                            )}
                            {emp.infraestructura !== 'no' && (
                                <p>
                                    <i className="fa-solid fa-building"></i><span className="font-modal">Infraestructura:</span> {emp.infraestructura}
                                </p>
                            )}
                            <p className='precios'>PRECIOS:</p><p></p>
                        </div>
                    </Typography>
                                        <div className="empleado-info">
                                            {(emp.precioDia !== 0 && emp.precioDia !== null) && (
                                                <>
                                                <p><strong>Día:</strong></p><p></p>
                                                    <p>1 hora</p>
                                                    <p>: $/{emp.precioDia}</p>
                                                </>
                                            )}
                                            {(emp.precioNoche !== 0 && emp.precioNoche !== null) && (
                                                <>
                                                <p><strong>Noche:</strong></p><p></p>
                                                    <p>1 hora</p>
                                                    <p>: ${emp.precioNoche}</p>
                                                </>
                                            )}
                                            {(emp.precioEventos !== 0 && emp.precioEventos !== null) && (
                                                <>
                                                    <p>Eventos/otros</p>
                                                    <p>: ${emp.precioEventos}</p>
                                                </>
                                            )}
                                        </div>
                                        
                                        <div className='datos-normal'>
                                            {emp.whatsapp && (
                                                <div className="datos-item">
                                                    <a href={emp.whatsapp} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> WhatsApp</a>
                                                </div>
                                            )}
                                            {emp.telefono && (
                                                <div className="datos-item">
                                                    <a href={emp.telefono} style={{ color: '#333' }}><i className="fa-solid fa-phone"></i> Resevaciones</a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                    <div className='datos-resposive'>
                                        {emp.whatsapp && (
                                            <div className="datos-item">
                                                <a href={emp.whatsapp} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> WhatsApp</a>
                                            </div>
                                        )}
                                        {emp.telefono && (
                                            <div className="datos-item">
                                                <a href={emp.telefono} style={{ color: '#333' }}><i className="fa-solid fa-phone"></i> Resevaciones</a>
                                            </div>
                                        )}
                                    </div>
                            </div>
                        ))}
                    </>
            ) : (
            <Typography variant="body1">
                No se encontraron datos del complejo.
            </Typography>
            )}
        </DialogContent>
        </BootstrapDialog>
    );
}