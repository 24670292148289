import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { IoClose, IoMenu, IoPerson } from "react-icons/io5"; // Añadimos IoPerson
import '../hojas-css/navbar.css';

const Navbar = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate("/", { replace: true });
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleNavigation = (path) => {
        toggleMenu();
        navigate(path);
    };

    return (
        <header className="header">
            <nav className="nav container">
                <li className="cerrar-sesion">
                    <NavLink
                        to="/"
                        className="btn-cerrarSesion"
                        onClick={(event) => {
                            event.preventDefault();
                            handleNavigation("/");
                        }}
                    >
                        <span>Inicio</span>
                    </NavLink>
                </li>

                <div className="nav__logo" onClick={toggleMenu}>
                    Huaral
                </div>

                <div className={`nav__menu ${menuOpen ? "active" : ""}`}>
                    <ul className="nav__list">
                        <li className="nav__item">
                            <NavLink to="/trabajndo" className="nav__link" onClick={() => handleNavigation('/trabajndo')}>
                                Lima
                            </NavLink>
                        </li>

                        <li className="nav__item">
                            <NavLink to="/trabajndo" className="nav__link" onClick={() => handleNavigation('/trabajndo')}>
                                Huacho
                            </NavLink>
                        </li>
                        <li className="nav__item">
                            <NavLink to="/trabajndo" className="nav__link" onClick={() => handleNavigation('/trabajndo')}>
                                Barranca
                            </NavLink>
                        </li>
                        <li className="cerrar-sesion">
                            <NavLink to="/get-started" className="btn-cerrarSesion" onClick={(event) => { event.preventDefault(); handleNavigation("/"); handleLogout(); }}>
                                <span>Cerrar sesión</span>
                            </NavLink>
                        </li>
                    </ul>
                    <div className="nav__close" onClick={toggleMenu}>
                        <IoClose />
                    </div>
                </div>

                <div className="nav__icons">
                    <div className="nav__toggle" onClick={toggleMenu}>
                        <IoMenu />
                    </div>
                    <div className="login-icon" onClick={() => navigate('/registro')}>
                        <IoPerson />
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
