import React, { useState, useEffect } from 'react';
import Navbar from '../../componentes/navbar.js';
import Slider from '../../componentes/slider.js';
import { MapaCampestre} from '../../componentes/map.js';
import { BotonRestaurante} from '../../componentes/botones2.js';
import Footer from '../../componentes/footer.js';
import CustomizedDialogs from './modalRestau.js';
import GlobalSearch from '../../componentes/GlobalSearch.js';
import '../../hojas-css/miniban.css';

function Polleria() {
    const [data, setData] = useState([]);
    const [selectedMovil, setSelectedMovil] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/restaurantes.php?restaurantes`)
        .then((response) => response.json())
        .then((data) => {
            setData(data.filter(item => item.id_roles === 8));
        })
        .catch(error => console.error('Error al obtener los datos:', error));
    }, []);

    const handleClickVerMas = (movil) => {
        setSelectedMovil(movil);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const filteredData = data.filter(movil =>
        movil.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );


    return (
        <div className="App">
            
            <div className='navbar-container'>
                <Navbar />
            </div>
            
            <div className='slider-container'>
                <Slider />
            </div>
            <h1 className="titulo-2">Restaurantes</h1>
            <GlobalSearch searchTerm={searchTerm} onSearchChange={setSearchTerm} />
            <div className='contenedor-padre2'> 
                <div className='contenedor-hijo'>
                        <div className='botones-container'>
                            <BotonRestaurante />
                        </div>
                        
                        <div className='miniban-container'>
                            <div className="column-container">
                                <h1>Restaurantes Campestres</h1>
                                {filteredData.map((restau, index) => (
                                    <div key={index} className="miniban-container">
                                        <img src={restau.imagenRestau} alt="Imagen de promo" className="miniban-image" />
                                        <div className="miniban-info">
                                            <h2>{restau.nombre}</h2>
                                            {restau.descripcion&& (
                                                <p >
                                                    {restau.descripcion}
                                                    </p>
                                            )}
                                            <p><i className="fa-solid fa-location-dot"></i> Direccion: {restau.direccion}</p>
                                            {restau.sitioWeb ? (
                                                <div className="link-item">
                                                <a href={restau.sitioWeb} style={{ color: 'blue' }}><i className="fa-solid fa-globe"></i> sitio Web</a>
                                                </div>
                                            ): null}
                                            {restau.facebook ? (
                                                <div className="link-item">
                                                <a href={restau.facebook} style={{ color: 'blue' }}><i className="fab fa-facebook"></i> Facebook</a>
                                                </div>
                                            ): null}

                                            <div className='iconos-horizontal'>
                                                {restau.wasap ? (
                                                    <div className="link-item">
                                                    <a href={restau.wasap} style={{ color: 'green' }}><i className="fa-brands fa-whatsapp"></i> whatsapp</a>
                                                    </div>
                                                ): null}
                                                {restau.telefono ? (
                                                    <div className="link-item">
                                                    <a href={restau.telefono} style={{ color: '#0088cc' }}><i className="fa-solid fa-phone"></i> Delivery</a>
                                                    </div>
                                                ): null}
                                                {restau.comoLlegar ? (
                                                    <div className="link-item">
                                                    <a href={restau.comoLlegar} style={{ color: '#666' }}><i className="fa-solid fa-car"></i> Como Llegar</a>
                                                    </div>
                                                ): null}
                                            </div>
                                            <button onClick={() => handleClickVerMas(restau)} className="ver-mas-btn">Ver {restau.nombre}</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            
                            <CustomizedDialogs 
                                open={isModalOpen}
                                handleClose={handleCloseModal}
                                restau={selectedMovil}
                            />

                        </div>
                </div>
                <div className='mapa-container'>
                    <MapaCampestre searchTerm={searchTerm}/>
                </div>
            </div>
            
            <div className='footer-container'>
                <Footer />
            </div>
            
        </div>
    );
}

export default Polleria;
